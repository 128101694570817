<template>
  <footer :class="$style.TheFooter">
    <div class="container_max" :class="$style.wrapper">
      <div :class="$style.content">
        <NuxtLink :to="localePath('/')" :class="$style.logo">
          <CommonTheLogo />
        </NuxtLink>

        <nav :class="$style.nav">
          <ul :class="$style.navList">
            <li
              :class="[
                $style.navItem,
                { [$style._active]: route.path === '/rules' },
              ]"
            >
              <NuxtLink :to="localePath('/rules')" :class="$style.navLink">
                {{ $t('rules') }}
              </NuxtLink>
            </li>
            <li
              :class="[
                $style.navItem,
                { [$style._active]: route.path === '/partners' },
              ]"
            >
              <NuxtLink :to="localePath('/partners')" :class="$style.navLink">
                {{ $t('partners') }}
              </NuxtLink>
            </li>
            <li
              :class="[
                $style.navItem,
                { [$style._active]: route.path === '/faq' },
              ]"
            >
              <NuxtLink :to="localePath('/faq')" :class="$style.navLink">
                FAQ
              </NuxtLink>
            </li>
            <li
              :class="[
                $style.navItem,
                { [$style._active]: route.path === '/blog' },
              ]"
            >
              <NuxtLink :to="localePath('/blog')" :class="$style.navLink">
                {{ $t('blog') }}
              </NuxtLink>
            </li>
            <li
              :class="[
                $style.navItem,
                { [$style._active]: route.path === '/contacts' },
              ]"
            >
              <NuxtLink :to="localePath('/contacts')" :class="$style.navLink">
                {{ $t('contacts') }}
              </NuxtLink>
            </li>
            <li
              :class="[
                $style.navItem,
                { [$style._active]: route.path === '/sitemap' },
              ]"
            >
              <NuxtLink :to="localePath('/sitemap')" :class="$style.navLink">
                {{ $t('sitemap') }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
        <div :class="$style.socials">
          <!--          <a href="https://www.facebook.com/swopexchange" target="_blank">-->
          <!--            <svg-->
          <!--              width="35"-->
          <!--              height="36"-->
          <!--              viewBox="0 0 35 36"-->
          <!--              fill="none"-->
          <!--              xmlns="http://www.w3.org/2000/svg"-->
          <!--            >-->
          <!--              <path-->
          <!--                d="M34.5295 18C34.5295 8.46494 26.7998 0.73526 17.2647 0.73526C7.72968 0.73526 0 8.46494 0 18C0 26.6172 6.31343 33.7598 14.5671 35.055V22.9906H10.1835V18H14.5671V14.1964C14.5671 9.86939 17.1447 7.4793 21.0883 7.4793C22.9766 7.4793 24.9529 7.8165 24.9529 7.8165V12.0652H22.776C20.6314 12.0652 19.9624 13.3962 19.9624 14.7629V18H24.7506L23.9852 22.9906H19.9624V35.055C28.2161 33.7598 34.5295 26.6172 34.5295 18Z"-->
          <!--                fill="#B5D841"-->
          <!--              />-->
          <!--              <path-->
          <!--                d="M23.9852 22.9906L24.7506 18H19.9624V14.7629C19.9624 13.3975 20.6314 12.0652 22.776 12.0652H24.9529V7.8165C24.9529 7.8165 22.9766 7.4793 21.0883 7.4793C17.1447 7.4793 14.5671 9.86939 14.5671 14.1964V18H10.1835V22.9906H14.5671V35.055C16.3547 35.3347 18.1748 35.3347 19.9624 35.055V22.9906H23.9852Z"-->
          <!--                fill="#081420"-->
          <!--              />-->
          <!--            </svg>-->
          <!--          </a>-->
          <a href="https://twitter.com/swopexchange">
            <svg
              width="39"
              height="34"
              viewBox="0 0 39 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5947 33.3056C27.0725 33.3056 34.9913 20.758 34.9913 9.8768C34.9913 9.52041 34.9913 9.16562 34.9682 8.81244C36.5088 7.6468 37.8386 6.20352 38.8954 4.55018C37.4589 5.21641 35.9349 5.65313 34.3743 5.84571C36.0176 4.81675 37.2475 3.19816 37.835 1.29127C36.2899 2.2505 34.5994 2.92646 32.8366 3.28996C31.6498 1.96984 30.0802 1.09569 28.3706 0.802781C26.661 0.509873 24.9068 0.814537 23.3795 1.66963C21.8521 2.52472 20.6367 3.88257 19.9214 5.53305C19.206 7.18353 19.0306 9.03462 19.4223 10.7999C16.2928 10.6357 13.2313 9.78488 10.4365 8.30274C7.64166 6.8206 5.17601 4.74025 3.19958 2.1967C2.19299 4.00943 1.8847 6.15533 2.33746 8.19748C2.79022 10.2396 3.97 12.0245 5.63659 13.1887C4.38386 13.1503 3.15841 12.7968 2.06394 12.158V12.2624C2.06444 14.1635 2.69355 16.0059 3.84456 17.4771C4.99558 18.9483 6.59763 19.9577 8.379 20.3342C7.22015 20.6649 6.00426 20.7132 4.82477 20.4755C5.32796 22.1115 6.30728 23.5421 7.62581 24.5674C8.94434 25.5926 10.5362 26.1613 12.1788 26.1938C10.5467 27.5357 8.6778 28.5279 6.67886 29.1136C4.67992 29.6992 2.59022 29.8668 0.529297 29.6068C4.12905 32.0233 8.3175 33.305 12.5947 33.2992"
                fill="#B5D841"
              />
            </svg>
          </a>
          <a href="tg://resolve?domain=swop_is">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.1603 35.2647C27.6953 35.2647 35.425 27.5351 35.425 18C35.425 8.46495 27.6953 0.73526 18.1603 0.73526C8.6252 0.73526 0.895508 8.46495 0.895508 18C0.895508 27.5351 8.6252 35.2647 18.1603 35.2647Z"
                fill="#B5D841"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.71051 17.8178C13.7435 15.6249 17.0997 14.1793 18.7789 13.4809C23.5735 11.4866 24.5698 11.1402 25.2192 11.1288C25.362 11.1262 25.6813 11.1616 25.8882 11.3295C26.0628 11.4712 26.1109 11.6627 26.1339 11.797C26.1569 11.9314 26.1855 12.2375 26.1628 12.4767C25.9029 15.2066 24.7787 21.8315 24.2067 24.8891C23.9647 26.1829 23.4882 26.6167 23.0269 26.6592C22.0243 26.7514 21.2629 25.9966 20.2919 25.3601C18.7724 24.364 17.9139 23.7439 16.439 22.772C14.7344 21.6487 15.8394 21.0313 16.8108 20.0223C17.065 19.7583 21.4825 15.7403 21.568 15.3758C21.5787 15.3302 21.5886 15.1603 21.4877 15.0705C21.3867 14.9808 21.2377 15.0115 21.1302 15.0359C20.9778 15.0705 18.5504 16.6749 13.8478 19.8493C13.1588 20.3224 12.5347 20.5529 11.9756 20.5408C11.3591 20.5275 10.1733 20.1923 9.29182 19.9058C8.21062 19.5543 7.35129 19.3685 7.42612 18.7716C7.4651 18.4607 7.89323 18.1428 8.71051 17.8178Z"
                fill="#081420"
              />
            </svg>
          </a>
        </div>
      </div>
      <div :class="$style.bottom">
        <div :class="$style.monitorings">
          <a target="_blank" :href="bestChangeLink" :class="$style.monitoring"
            ><img
              src="/images/bestchange.png"
              :title="$t('BestChange.ru - Мониторинг обменников в интернете')"
              width="88"
              height="31"
              rel="nofollow"
              border="0"
          /></a>
          <a
            :class="$style.monitoring"
            href="https://wellcrypto.io/ru/exchangers/"
            title="Сервис поиска выгодного курса обмена"
            ><img
              src="https://wellcrypto.io/i/b_1.svg"
              width="88"
              height="31"
              border="0"
          /></a>
          <a
            :class="$style.monitoring"
            href="https://exchangesumo.com"
            target="_blank"
            title="Exchangesumo - лучший мониторинг обменников"
            ><img
              src="/images/exchangeSumo.svg"
              alt="Exchangesumo - лучший мониторинг обменников"
              rel="nofollow"
              style="width: 100%; height: 100%"
          /></a>
          <a
            :class="$style.monitoring"
            href="https://crypto-control.org/monitoring"
            target="_blank"
            title="Cryptocontrol"
            ><img
              src="/images/cryptocontrol.jpg"
              alt="Cryptocontrol"
              rel="nofollow"
              style="width: 100%; height: 100%"
          /></a>
          <a
            :class="$style.monitoring"
            href="https://bits.media/exchanger/swop-is/"
            target="_blank"
            title="bitsmedia"
            ><img
              src="/images/bitsmedia.png"
              alt="bitsmedia"
              rel="nofollow"
              style="width: 100%; height: 100%"
          /></a>
          <!--          <a-->
          <!--            :class="$style.monitoring"-->
          <!--            target="_blank"-->
          <!--            href="https://exnode.ru/"-->
          <!--          >-->
          <!--            <img-->
          <!--              src="https://exnode.ru/exnode-logo.png"-->
          <!--              alt="Мониторинг обменников Exnode"-->
          <!--              rel="nofollow"-->
          <!--              title="Exnode - Мониторинг обменников"-->
          <!--              width="88"-->
          <!--              height="31"-->
          <!--              border="0"-->
          <!--            />-->
          <!--          </a>-->
        </div>
      </div>
      <p :class="$style.txt">
        {{ $t('Сайт предназначен только для лиц старше 18 лет') }}
      </p>
    </div>
  </footer>
</template>

<script setup>
const route = useRoute();
const localePath = useLocalePath();
const { locale } = useI18n();
const bestChangeLink = computed(() => {
  const domain = locale.value === 'ru' ? 'ru' : 'com';
  return `https://www.bestchange.${domain}/?p=1282781`;
});
</script>

<style lang="scss" module>
.TheFooter {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #1d2532;
  padding: 2rem 0;

  @include respond-to(xs) {
    height: auto;
    padding-top: 49px;
    padding-bottom: 49px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 0 3.6rem;

  @include respond-to(sm) {
    flex-direction: column;
  }
}

.logo {
  display: inline-flex;
  flex-shrink: 0;
  max-width: 100%;

  svg {
    width: 175px;
    max-width: 100%;
    height: 39px;
  }

  @include respond-to(xs) {
    svg {
      width: 169px;
      height: 38px;
    }
  }
}

.nav {
  margin-left: auto;

  @include respond-to(sm) {
    margin-right: auto;
  }

  @include respond-to(xs) {
    margin-top: 40px;
  }
}

.navList {
  display: flex;
  align-items: center;

  @include respond-to(xs) {
    flex-direction: column;
    justify-content: center;
  }
}

.navItem {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.8rem;

  &:hover {
    .navLink {
      color: $brand-green;
    }
  }

  &._active {
    .navLink {
      color: $brand-green;
    }
  }

  &:not(:last-child) {
    margin-right: 4.15rem;
  }

  @include respond-to(xs) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 11px;
    }
  }
}

.navLink {
  transition: all $base-transition;
}

.socials {
  display: none;
  margin-top: 45px;

  @include respond-to(xs) {
    display: flex;
    gap: 17px;
  }
}

.monitorings {
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 1rem;

  @include respond-to(sm) {
    display: none;
  }

  @include respond-to(xs) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 2rem;
  }
}

.monitoring {
  width: 88px;
  height: 31px;
}

.txt {
  font-size: 12px;
}
</style>
